<template>
  <div class="relative min-h-screen md:flex">
    <SideNavBar />

    <div class="flex-1 p-10 text-2xl">
      <!-- header -->
      <div class="flex justify-between flex-wrap md:flex-nowwrap items-center">
        <div class="flex">
          <ChevronLeftIcon
            @click="naviageToManageProductCategory"
            class="h-7 w-7 pt-2 cursor-pointer"
          />
          <h2 class="font-semibold">New Product</h2>
        </div>
      </div>
      <!-- end of header -->

      <!-- content -->
      <div v-if="isLoading" class="loader"></div>

      <div v-if="!isLoading" class="mt-5">
        <form @submit.prevent="addProduct" class="card pt-2 form-control">
          <label class="label"
            ><span class="label-text text-black"
              >Name <span class="text-red-500">*</span></span
            ></label
          >
          <input
            v-model="productName"
            type="text"
            class="input input-md input-bordered w-full"
            required
          />

          <label class="label"
            ><span class="label-text text-black"
              >Category <span class="text-red-500">*</span></span
            ></label
          >
          <select
            v-model="productCategory"
            class="select select-bordered w-full"
            required
          >
            <option
              v-for="category in categoryList"
              :key="category.id"
              :value="category.id"
            >
              {{ category.productCategoryName }}
            </option>
          </select>

          <label class="label"
            ><span class="label-text text-black"
              >Short Description <span class="text-red-500">*</span></span
            ></label
          >
          <input
            type="text"
            v-model="productShortDesc"
            class="input input-md input-bordered w-full"
            required
          />

          <label class="label"
            ><span class="label-text text-black">Full Details</span></label
          >
          <ckeditor
            class="text-xs"
            v-model="productFullDetails"
            :editor="editor"
          ></ckeditor>

          <label class="label"
            ><span class="label-text text-black"
              >Product Image <span class="text-red-500">*</span></span
            ></label
          >
          <DragDropImage
            @changed="handleProductImage"
            :max="1"
            clearAll="Clear All"
            isRequired="true"
            maxError="Maximum one file only"
          />

          <br />
          <button class="btn bg-green-500 border-none mt-3">Submit</button>
        </form>
      </div>
      <!-- end of content -->
    </div>
  </div>
</template>

<script>
import SideNavBar from "../../components/SideNavBar.vue";
import DragDropImage from "../../components/DragDropImage.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { ChevronLeftIcon } from "@heroicons/vue/outline";

export default {
  name: "AddProduct",
  components: { SideNavBar, DragDropImage, ChevronLeftIcon },
  data() {
    return {
      isLoading: false,
      editor: ClassicEditor,
      categoryList: [],
      productName: "",
      productCategory: "",
      productShortDesc: "",
      productFullDetails: "",
      productImageSrc: [],
    };
  },
  mounted() {
    this.getProductCategoryList();
  },
  methods: {
    handleProductImage(files) {
      this.productImageSrc = files[0];
    },
    getProductCategoryList() {
      this.axios({
        url: "/productCategory/getProductCategory",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          this.categoryList = response.data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({
              name: "Login",
              query: {
                error:
                  "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
              },
            });
            this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
          }
        });
    },
    addProduct() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("productName", this.productName);
      formData.append("productCategoryID", this.productCategory);
      formData.append("productShortDesc", this.productShortDesc);
      formData.append("productFullDetails", this.productFullDetails);
      formData.append("productImageSrc", this.productImageSrc);

      this.axios
        .post("/product/addProduct", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "success",
            title: response.data["message"],
          });
          this.isLoading = false;
          this.naviageToManageProductCategory();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({
              name: "Login",
              query: {
                error:
                  "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
              },
            });
            this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
          }
        });
    },
    naviageToManageProductCategory() {
      this.$router.push({ name: "ManageProduct" });
    },
  },
};
</script>

<style>
@import "../../assets/CustomCKEditorStyle.css";
</style>